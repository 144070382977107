import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ContentBlock, ContentBlockAction, Table} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import {sortItems} from "../../../common/utils/helper";
import {CmpSirdataListStackRow} from "../../../component/snippet";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list/SirdataListLanguage";
import {SirdataListStack} from "../../../api/model/cmp/list/sirdata-list/SirdataListStack";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list/SirdataListStackField";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersionField";
import ModalCreateCmpSirdataListStack from "../../modal/cmp-sirdata-list/ModalCreateCmpSirdataListStack";
import ModalEditCmpSirdataListStack from "../../modal/cmp-sirdata-list/ModalEditCmpSirdataListStack";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersion";
import ModalTranslateCmpSirdataListStacks from "../../modal/cmp-sirdata-list/ModalTranslateCmpSirdataListStacks";

type CmpSirdataListStacksEditableProps = {
    latestVersion: SirdataListVersion;
    globalVendorList: GlobalVendorList;
    listLanguage: SirdataListLanguage;
    onChange: () => void;
};

const CmpSirdataListStacksEditable: FC<CmpSirdataListStacksEditableProps> = ({latestVersion, globalVendorList, listLanguage, onChange}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const [stacks, setStacks] = useState<SirdataListStack[]>([]);
    const [isShowModalCreateCmpSirdataListStack, setShowModalCreateCmpSirdataListStack] = useState(false);
    const [isShowModalTranslateCmpSirdataListStacks, setShowModalTranslateCmpSirdataListStacks] = useState(false);
    const [activeEditStack, setActiveEditStack] = useState<SirdataListStack>();

    useEffect(() => {
        setStacks(listLanguage.stacks);
    }, [listLanguage.stacks]);

    const handleSortStacks = (field: string, reverseOrder?: boolean) => {
        setStacks((prevState) => sortItems([...prevState], field, reverseOrder));
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCmpSirdataList(`field.${SirdataListVersionField.STACKS}`)},
                actions: (
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ContentBlockAction action={new Action(textCmpSirdataList("action.translate"), {name: "language"})} onClick={() => setShowModalTranslateCmpSirdataListStacks(true)}/>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreateCmpSirdataListStack(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <Table
                onSort={handleSortStacks}
                columns={[
                    {width: 5, label: textCmpSirdataList(`field.stack.${SirdataListStackField.ID}`), sort: {field: SirdataListStackField.ID}},
                    {width: 40, label: textCmpSirdataList(`field.stack.${SirdataListStackField.NAME}`)},
                    {width: 45, label: textCmpSirdataList("field.purposes")},
                    {width: 5}
                ]}
                messageIfEmpty={textCmpSirdataList("message.no_stacks")}
            >
                {stacks.map((stack) =>
                    <CmpSirdataListStackRow
                        key={stack.id}
                        stack={stack}
                        state={stack.getState(latestVersion)}
                        onEdit={() => setActiveEditStack(stack)}
                    />
                )}
            </Table>
            <ModalCreateCmpSirdataListStack
                globalVendorList={globalVendorList}
                extraPurposes={listLanguage.purposes}
                active={isShowModalCreateCmpSirdataListStack}
                onClose={async (refresh) => {
                    setShowModalCreateCmpSirdataListStack(false);
                    refresh && onChange();
                }}
            />
            <ModalEditCmpSirdataListStack
                initStack={activeEditStack}
                globalVendorList={globalVendorList}
                extraPurposes={listLanguage.purposes}
                onClose={async (refresh) => {
                    setActiveEditStack(undefined);
                    refresh && onChange();
                }}
            />
            <ModalTranslateCmpSirdataListStacks
                active={isShowModalTranslateCmpSirdataListStacks}
                onClose={async (refresh) => {
                    setShowModalTranslateCmpSirdataListStacks(false);
                    refresh && onChange();
                }}
            />
        </ContentBlock>
    );
};

export default CmpSirdataListStacksEditable;
