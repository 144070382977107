import {FunctionComponent} from "react";
import {IconTooltip, TableColumn, TableRow} from "@sirdata/ui-lib";
import {SirdataListPurpose} from "../../../../api/model/cmp/list/sirdata-list/SirdataListPurpose";
import {SirdataListEntityState} from "../../../../api/model/cmp/list/sirdata-list/SirdataListEntityState";
import {CmpSirdataListEntityState} from "../../index";

type CmpSirdataListPurposeRowProps = {
    purpose: SirdataListPurpose;
    state?: SirdataListEntityState;
};

const CmpSirdataListPurposeRow: FunctionComponent<CmpSirdataListPurposeRowProps> = ({purpose, state}) => {
    return (
        <TableRow>
            <TableColumn>
                {purpose.id}
                <CmpSirdataListEntityState state={state}/>
            </TableColumn>
            <TableColumn>
                {purpose.name}
                <IconTooltip
                    icon={{name: "help"}}
                    text={purpose.description}
                />
            </TableColumn>
        </TableRow>
    );
};

export default CmpSirdataListPurposeRow;
